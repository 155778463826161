import React from "react";
import ReactHtmlParser from "html-react-parser";

import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import { cn } from "../../utils";
import * as styles from "./LegalPage.module.scss";
import ILegalPage from "./LegalPage.type";

const LegalPage = (props: ILegalPage) => (
    <section>
        <div className={cn(styles.container, "container")}>
            <TitleUnderlineParagraph
                headingLevel="h1"
                heading={props.pageTitle}
                underline="center"
                className={styles.title}
            />
            <div className={styles.content}>
                {ReactHtmlParser(props.content)}
            </div>
        </div>
    </section>
);

export default LegalPage;
